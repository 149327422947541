import React, { useEffect, Fragment } from "react";
import { scrollToElement } from "../../../utility/utils";

const CashBack = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section mt-0">
                <div className="kuda-section--inner">
                    <div className="kuda-section--100 pb-0 inner-spotlight--heading">
                        <h6 className=" text-center text-xl text-xlbold color-primary title-bottom--spacing">
                            Terms & Conditions
                        </h6>
                    </div>

                    <div className="kuda-section--100 ">
                        <p className="color-black title-bottom--spacing">
                            Kuda Microfinance Bank Limited (“Kuda”) is offering existing customers the opportunity to participate in its end of the year Reward Program (the “Program”).  The Program is a means through which Kuda rewards certain eligible customers who elect to participate in the Program (the “Participants”).  There are a number of campaigns under the Program which Participants can engage in and benefit from.
                        </p>
                        <p className="color-black title-bottom--spacing">
                            The Program in its entirety is governed by these Terms and Conditions (in addition to the general terms and conditions that apply to your Kuda bank account) and by participating in the Program, a Participant agrees to be bound by the terms specified herein as well as any subsequent changes or modifications made by Kuda to these terms.
                        </p>
                        <p className="color-black title-bottom--spacing">
                            Please note the following preliminary points in relation to the Program:
                            <ul className="diclaimer-list">
                                <li>
                                    It will only be available to customers that receive notifications from Kuda that they have been preselected to take steps to become Participants;
                                </li>
                                <li>
                                    It will be available only for a fixed period only as set out below (the “Offer Period”);
                                </li>
                                <li>
                                    The rewards under it are all in form of cash that would be credited to the Participants’ Kuda accounts if they meet all the requirements set out in these Terms and Conditions;
                                </li>
                                <li>
                                    It is only available to Tier-3 customers;
                                </li>
                                <li>
                                    It is not available to Kuda Business Accounts; and
                                </li>
                                <li>
                                    Customers can only participate or claim any of the rewards under the Program one time during the Offer Period.
                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="kuda-section--100">
                        <h6 className=" text-left text-xlbold color-primary title-bottom--spacing">
                            The Program:
                        </h6>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black title-bottom--spacing">
                                <span className="text-bold">Offer Period:</span> 21st November - 19th December 2022 (4 weeks)
                            </p>
                            <p className="color-black title-bottom--spacing">
                                <span className="text-bold">Steps:</span> To be entitled to claim the Reward, an Eligible Participant must activate their physical Visa debit card and carry out at least three financial transactions using the card within the Offer Period.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                <span className="text-bold">Reward:</span> Cashback / refund on the customers third, sixth and ninth card transactions.  The reward sum is a percentage of the amount spent by the customer subject to the cap set out below.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                Third card transaction – 3% capped at NGN300 (three hundred Naira)
                            </p>
                            <p className="color-black title-bottom--spacing">
                                Sixth card transaction – 4% capped at NGN400 (four hundred Naira)
                            </p>
                            <p className="color-black title-bottom--spacing">
                                Ninth card transaction – 5% capped at NGN 500 (five hundred Naira).
                            </p>
                        </div>
                    </div>

                    <div className="kuda-section--100">
                        <h6 className=" text-left text-xlbold color-primary title-bottom--spacing">
                            General Terms:
                        </h6>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black title-bottom--spacing">
                                The claiming of Rewards by a Participant is subject to verification by Kuda and may depend on priority thresholds set by Kuda such that the response time of Participants could be a determining factor in the verification processes.  A Reward paid or awarded to a Participant in relation to a verified claim automatically disqualifies the Participant from earning additional rewards under the Program.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                Kuda reserves the right to modify or amend at any time and without notice to any party, these Terms and Conditions and/or the methods through which rewards are earned and shall not be liable for any such change or modification.  Kuda also reserves the right to disqualify any Participant from participation in the Program at any time at our sole discretion, including without limitation if he/she does not comply with any of these Terms and Conditions or otherwise fails to comply with any applicable laws.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                A Participant hereby undertakes to indemnify Kuda, its group member companies, affiliates and subsidiaries, together with their respective employees, directors, officers and agents from any and all claims, actions, demands, damages, losses, liabilities, costs or expenses caused by, arising out of, or in connection with the Participant’s participation in the Program, including without limitation the use or misuse of the Rewards given under the Program.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                In relation to the Program or Rewards, a Participant undertakes not to:
                                <ul className="diclaimer-list">
                                    <li>
                                        resell, trade, barter or otherwise generate income by providing access to the Program to others;
                                    </li>
                                    <li>
                                        conduct any illegal activity or solicit the performance of any illegal activity;
                                    </li>
                                    <li>
                                        attempt to gain unauthorized access to the Program, other accounts, computer systems, or networks connected to the Program;
                                    </li>
                                    <li>
                                        impersonate any person or otherwise misrepresent their identity;
                                    </li>
                                    <li>
                                        violate any applicable law.
                                    </li>
                                </ul>
                            </p>
                            <p className="color-black title-bottom--spacing">
                                Kuda reserves the right to prohibit a Participant from participating in the Program or receiving a Reward, if it determines that such a Participant is attempting to undermine (or has undermined) the fairness, integrity or legitimate operation of the Program in any way by (a) cheating, hacking, deception, or any other unfair conduct; (b) using any affiliate website, affiliate network properties or automated systems to participate in the Program; (c) using multiple or fake emails addresses or accounts, fictitious identities or bots or other device or artifice to participate in the Program or receive a Reward.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                The Participants understand and agree that Kuda shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss or use of data or other intangible losses, resulting from the use or the inability to use the Program; (ii) transactions entered into through, from, or as a result of the Program; (iii) unauthorised access to or alteration of their data; (iv) statements or conduct of any third party on or in connection with the Program.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                If one or more provisions of these Terms and Conditions are void or invalid, this will not affect the validity of the remaining conditions herein.
                            </p>
                            <p className="color-black title-bottom--spacing">
                                These Terms and Conditions shall be governed and construed in accordance with the laws of the Federal Republic of Nigeria.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CashBack