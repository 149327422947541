import React from "react"
import CashBack from "../../components/body/pages/incentive-program/cashback"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const CashBackRefund = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/incentive-program/cashback-refund/"}
      title="Kuda Incentive Program"
    />
    <CashBack />
  </NoheaderFooter>
)
export default CashBackRefund
